









import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component
export default class VCheckButton extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: false }) disableUncheck? : boolean;
  @Prop({ default: false }) disabled? : boolean;

  @Watch('value', { deep: true })
  onValueChange(value: boolean) {
    this.checked = value;
  }

  checked = this.value;

  get buttonClass() {
    return {
      'v-check-button--checked': this.checked
    };
  }

  toggle() {
    if (this.disabled) {
      return;
    }

    if (this.disableUncheck) {
      if (!this.checked) {
        this.checked = true;
      }
    } else {
      this.checked = !this.checked;
    }

    this.$emit('input', this.checked);
  }
}
