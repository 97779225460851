

















































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import VCheckButton from "@/components/VCheckButton.vue";
import moment from "moment";
export const DATE_FORMAT = "YYYY-MM-DD";

export type DateString = string | Date | undefined;

export interface DatePickerData {
  date?: DateString;
  range?: {
    from: DateString;
    to: DateString;
  };
  compare?: {
    from: DateString;
    to: DateString;
  };
}

@Component({
  components: {
    VCheckButton
  }
})
export default class DatePicker extends Vue {
  @Prop({ default: undefined }) date?: Date | string | undefined;
  @Prop({ default: undefined }) dateTo?: Date | string | undefined;
  @Prop({ default: null }) endDate?: Date | string | null;
  @Prop({ default: "DD/MM/YYYY" }) format?: string;
  @Prop({ default: "range" }) mode?: string;
  @Prop({ default: null }) placeholder?: string | null;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: "left" }) dropdownAlign?: string;
  @Prop({ default: false }) compare?: boolean;

  @Watch("date")
  onDateChange(value: Date | string) {
    this.dateOne = value;

    if (!this.dateOne) {
      this.selected = '';
    }
  }

  @Watch("dateTo")
  onDateToChange(value: Date | string) {
    this.dateTwo = value;

    if (!this.dateTwo) {
      this.selected = '';
    }
  }

  @Watch("compareYearEnabled")
  onCompareYearEnabledChange(value: boolean) {
    if (value == false && this.rangeSelected) {
      this.compared.type = "period";
    }
  }

  dateOne: Date | string | undefined = "";
  dateTwo: Date | string | undefined = "";
  protected datepicker: any | null = null;
  visible = false;
  selected: string | null = null;
  firstOpen = true;

  compared: any = {
    type: "year",
    from: null,
    to: null
  };

  created() {
    this.dateOne = this.date;
    this.dateTwo = this.dateTo;

    if (this.date || this.dateTo) {
      this.selected = this.formattedValue();
    }
  }

  mounted() {
    this.datepicker = this.$refs.datepicker as any;
    this.datepicker.width = 220;
  }

  get singleMode() {
    return this.mode == "single";
  }

  get rangeMode() {
    return this.mode == "range";
  }

  get monthsToShow() {
    return this.rangeMode ? 2 : 1;
  }

  get randomValue() {
    return Math.random()
      .toString(36)
      .substr(2, 9);
  }

  get triggerId() {
    return "datepicker-" + this.randomValue;
  }

  get dateOneChanged() {
    return this.dateOne != moment(this.date).format(DATE_FORMAT);
  }

  get dateTwoChanged() {
    return this.dateTwo != moment(this.dateTo).format(DATE_FORMAT);
  }

  get compareYearRange() {
    if (this.rangeSelected == false) {
      return null;
    } else {
      const range = this.getCompareRange("year");
      return (
        moment(range.from).format(this.format) +
        " - " +
        moment(range.to).format(this.format)
      );
    }
  }

  get comparePeriodRange() {
    if (this.rangeSelected == false) {
      return null;
    } else {
      const range = this.getCompareRange("period");
      return (
        moment(range.from).format(this.format) +
        " - " +
        moment(range.to).format(this.format)
      );
    }
  }

  get compareYearEnabled() {
    return this.rangeDays <= 365;
  }

  get rangeSelected() {
    return this.dateOne != "" && this.dateTwo != "";
  }

  get rangeDays() {
    if (this.rangeSelected == false) {
      return 0;
    }

    const from = moment(this.dateOne);
    const to = moment(this.dateTwo);

    return to.diff(from, "days") + 1;
  }

  toggleDropdown() {
    this.visible = !this.visible;

    if (this.visible && this.firstOpen && this.endDate)
      this.$nextTick(() => {
        if (this.datepicker) {
          this.datepicker.startingDate = moment(this.endDate)
            .subtract(2, "months")
            .startOf("month");
          this.datepicker.generateMonths();
          this.firstOpen = false;
        }
      });
  }

  closeDropdown() {
    if (this.visible) {
      this.visible = false;
    }
  }

  confirmRange() {
    this.closeDropdown();
    this.selected = this.formattedValue();

    if (this.dateOne && this.dateTwo) {
      this.emitSelected();
    }
  }

  formattedValue() {
    let value = "";

    if (this.dateOne) {
      value = moment(this.dateOne).format(this.format);
    }
    if (this.dateTwo) {
      value += " - " + moment(this.dateTwo).format(this.format);
    }

    return value;
  }

  selectLastDays(days: number) {
    if (this.datepicker) {
      const start = moment(this.endDate).subtract(days - 1, "days");
      const end = moment(this.endDate);
      this.datepicker.selectedDate1 = start.format(DATE_FORMAT);
      this.datepicker.selectedDate2 = end.format(DATE_FORMAT);
    }
  }

  selectStartOfToToday(startOf: "month" | "year" | "isoWeek") {
    if (this.datepicker) {
      const end = moment(this.endDate);
      const start = end.clone().startOf(startOf);

      this.datepicker.selectedDate1 = start.format(DATE_FORMAT);
      this.datepicker.selectedDate2 = end.format(DATE_FORMAT);
    }
  }

  emitSelected() {
    let data: DatePickerData;

    if (this.singleMode) {
      data = {
        date: this.dateOne
      };

      this.$emit("selected", data);
    } else {
      data = {
        range: {
          from: this.dateOne,
          to: this.dateTwo
        }
      };

      if (this.compare) {
        data.compare = this.getCompareRange(this.compared.type);
      }

      this.$emit("selected", data);
    }
  }

  getCompareRange(
    type: string
  ): {
    from: Date | string | undefined;
    to: Date | string | undefined;
  } {
    if (!this.rangeSelected) {
      return {
        from: undefined,
        to: undefined
      };
    }

    const dateOne = moment(this.dateOne);
    const dateTwo = moment(this.dateTwo);

    let from, to;

    if (type == "year") {
      from = moment(this.dateOne).subtract("1", "years");
      to = moment(this.dateTwo).subtract("1", "years");
    } else {
      const startOfMonth = dateOne.clone().startOf("month");
      const endOfMonth = dateTwo.clone().endOf("month");

      if (
        dateOne.isSame(startOfMonth, "day") &&
        dateTwo.isSame(endOfMonth, "day")
      ) {
        const diff = dateTwo.diff(dateOne, "months");
        from = dateOne.subtract(diff + 1, "months");
        to = dateTwo.subtract(diff + 1, "months");
        to.endOf("month");
      } else {
        const diff = dateTwo.diff(dateOne, "days");
        from = dateOne.subtract(diff + 1, "days");
        to = moment(this.dateOne).subtract("1", "days");
      }
    }

    return {
      from: from.format(DATE_FORMAT),
      to: to.format(DATE_FORMAT)
    };
  }

  onDateOneSelected(value: Date | string) {
    this.dateOne = value;
    this.$emit("date-selected", this.dateOne);

    if (this.singleMode && this.dateOneChanged) {
      // this.$emit('selected', this.dateOne);
      this.emitSelected();
    }
  }

  onDateTwoSelected(value: Date | string) {
    this.dateTwo = value;
    this.$emit("date-to-selected", this.dateTwo);
  }

  onClosed() {
    if (this.rangeMode) {
      if (
        this.dateOne &&
        this.dateTwo &&
        (this.dateOneChanged || this.dateTwoChanged)
      ) {
        // this.$emit('selected', [this.dateOne, this.dateTwo]);
        this.emitSelected();
      }
    }
  }
}
